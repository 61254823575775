/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-11-13 14:27:06
 * @Last Modified by: zhujial
 * @Last Modified time: 2024-04-28 13:59:12
 * @Description: 微前端入口
 */

import '@babel/polyfill';
import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Element from 'element-ui';
import singleSpaVue from 'single-spa-vue';
import storeData from '@/store';
import pathArr from '@/utils/router-data';
import fetch from '@/utils/fetch';
import configs from '@/config';
import { getUrlParame } from '@/utils/utils';
import SlotPreview from '@/components/slot-preview/index';
import '@/styles/common-upms';

function init({ publicPath = 'report', routes = {} }, callback) {
    Vue.config.productionTip = false;
    const env = process.env.NODE_ENV;
    Vue.config.silent = env !== 'development';
    Vue.config.devtools = true;
    // localStorage.setItem('tableHeightSet', 10);
    localStorage.removeItem('tableHeightSet');
    const menuObj = {}; // 项目内菜单
    const router = new VueRouter({
        mode: 'history',
        routes
    });

    // Sentry.init({
    //     Vue,
    //     dsn: 'https://a96395fc72e843368281f1ae4f9e6d2e@sentry.udplat.com/21',
    //     environment: process.env.NODE_ENV,
    //     integrations: [
    //         new Integrations.BrowserTracing({
    //             routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    //         })
    //     ],
    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for performance monitoring.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 1.0
    // });

    Vue.use(VueRouter);
    Vue.use(Element);
    Vue.use(SlotPreview);
    if (callback)callback(Vue, router);
    const store = storeData;
    Vue.mixin({
        methods: {
            $fetch(options) {
                // 避免应用互相污染
                return this.$root.fetch(options);
            }
        }
    });
    // Vue.prototype.$bkConfig = configs;
    const appOptions = {
        // eslint-disable-next-line
        render: h => <div id="app" class="global-wrapper">
            <router-view></router-view>
        </div>,
        methods: {
            fetch: config => fetch(config),
            /**
             * 获得项目列表菜单
             * @param {*} data 菜单
             */
            getOuterPath(data) {
                const arrPath = [
                    { path: '/report/quantity-manage/province-list', label: '返回至项目外' },
                    { path: '/report/quantity-manage/product-org/province-list', label: '返回至项目外' }
                ];
                let value = '';
                for (let i = 0; i < data.length; i += 1) {
                    const item = data[i];
                    if (item.children) {
                        const val = this.getOuterPath(item.children);
                        if (val) {
                            value = val;
                        }
                    } else {
                        for (let n = 0; n < arrPath.length; n += 1) {
                            const target = arrPath[n];
                            if (target.path === item.path) {
                                value = JSON.parse(JSON.stringify(target));
                                // 药品耗材
                                value.path += `?gCode=${this.$route.query.gCode}`;
                                // console.log('搜索入口菜单', target, item);
                            }
                        }
                    }
                    if (value) break;
                }
                return value;
            },
            loadPojectM() {
                // 微前端 项目内外处理
                const pathUrl = this.$route.path;
                if (window.Store && process.env.NODE_ENV !== 'development') {
                    const itemId = getUrlParame('tenditmId') || getUrlParame('rpupCntItemId');
                    // 查询项目内菜单
                    if (itemId && pathUrl !== '/report/quantity-manage/province-list/volume-edit') {
                        this.$root.fetch({
                            url: '/web/auth/item/menu/query_item_upms_menu',
                            method: 'post',
                            params: {
                                tenditmId: itemId,
                                tenditmType: localStorage.getItem('system_id')
                            }
                        }).then(res => {
                            if (res) {
                                menuObj[itemId] = res.data;
                                if (window.Store) {
                                    window.Store.commit('GET_APPLY_MENU', res.data);
                                    // 添加项目名称
                                    window.Store.commit('SET_INNER_PRO_NAME', res.data[0].tenditmName);
                                }
                            }
                        });
                    } else {
                        // 查询项目外菜单
                        this.$root.fetch({
                            url: '/web/auth/role/query_user_upms_menu',
                            params: {
                                tenditmType: 1
                            }
                        }).then(res => {
                            if (res) {
                                menuObj.outer = res.data;
                                // 清除菜单
                                window.Store.commit('ClEAN_APPLY_MENU');
                                window.Store.commit('GET_APPLY_MENU', res.data);
                                // 设置项目列表
                                const id = localStorage.getItem('system_id');
                                let app = '';
                                if (id) {
                                    app = res.data.find(item => item.id === id);
                                } else {
                                    const route = this.$route.path.split('/')[1];
                                    app = res.data.find(item => item.id === route);
                                }
                                if (app && app.children) {
                                    const path = this.getOuterPath(app.children);
                                    if (path) {
                                        // 设置返回项目外的路由地址
                                        window.Store.commit('SET_BACK_OUTER', path);
                                    }
                                }
                            }
                        });
                    }
                }
            }
        },
        mounted() {
            this.loadPojectM();
        },
        watch: {
            $route() {
                this.loadPojectM();
            }
        },
        computed: {
            bkConfig: () => configs
        },
        store,
        router
    };
    // 区分项目内，项目外菜单
    router.beforeEach((to, from, next) => {
        const { path: toPath, query } = to;
        const { tenditmId, rpupCntItemId, tenditmType, gCode, subType } = query;
        const { tenditmId: fromItemId, rpupCntItemId: fromCntItemId, tenditmType: fromItemType, gCode: fGcode, subType: fsubType } = from.query;
        console.log(subType, fsubType);

        // const urlArr = toPath.split('/');
        const token = localStorage.getItem('token');
        if (gCode) {
            localStorage.setItem('gCode', gCode);
        }
        const loginPath = ['/login', '/portal', '/aup'];
        const outerPath = ['/report/quantity-manage/province-list', '/report/quantity-manage/product-org/province-list'];
        // 回到登录
        if (!token && to.path !== loginPath) {
            next({
                path: loginPath,
                query: {
                    path: to.fullPath
                }
            });
        }
        // 判断是否需要tenditmId
        let notRequireId = false;
        // for (let i = 0; i < urlArr.length; i += 1) {
        //     if (pathArr.includes(urlArr[i])) {
        //         notRequireId = true;
        //         break;
        //     }
        // }
        if (pathArr.includes(toPath)) {
            notRequireId = true;
        }

        // 添加项目id
        if (outerPath.indexOf(toPath) === -1 && !notRequireId && !tenditmId && !rpupCntItemId && !tenditmType && (fromItemId || fromCntItemId) && loginPath.indexOf(toPath) === -1) {
            next({
                path: toPath,
                query: {
                    subType: fsubType,
                    tenditmId: fromItemId,
                    rpupCntItemId: fromCntItemId,
                    tenditmType: fromItemType,
                    gCode: localStorage.getItem('gCode'),
                    ...to.query
                }
            });
        } else if (!gCode && fGcode && (!(to.path === '/login'))) {
            if (outerPath.indexOf(toPath) > -1) {
                // 项目外菜单不需要项目id
                delete query.tenditmId;
                delete query.rpupCntItemId;
                // window.Store.commit('SET_INNER_PRO_NAME', '');
            }
            next({
                path: toPath,
                query: {
                    gCode: fGcode,
                    subType: fsubType,
                    ...to.query
                }
            });
        } else {
            next();
        }
    });
    // 微前端
    let vueLifecycles = {};

    if (env === 'development') {
        // 手动挂载
        window.App = new Vue(appOptions).$mount('#app');
    } else {
        // 注册应用
        setPublicPath(publicPath);
        // 保存路由信息到全局
        if (window.Store) {
            window.Store.commit('SAVE_ROUTE_DATA', routes);
        }
        vueLifecycles = singleSpaVue({
            Vue,
            appOptions
        });
    }

    return vueLifecycles;
}

// 项目启动的钩子
export default init;
